import { IEnvironment } from './models/environment.model';

export const environment: IEnvironment = {
  name: 'staging',
  tenantId: 'luminapain-staging-1itzn',
  apiURL: 'https://api.luminapain-staging.com',
  graphQLEndpoint: '/graphql/mobile',
  diagnosesEndpoint: '/mgmt/diagnoses',
  firebaseConfig: {
    apiKey: 'AIzaSyB7o9VuUhHuCcGNrQVgL4emdHjtZNL6ozw',
    authDomain: 'luminapain.firebaseapp.com',
    projectId: 'luminapain',
    storageBucket: 'luminapain.appspot.com',
    messagingSenderId: '981932162284',
    appId: '1:981932162284:web:a98e898dc370cf6b9239c9',
    signInFlow: 'popup',
  },
  googleCalendarId: 'Y2FyZXRlYW1AbHVtaW5hcGFpbi1zdGFnaW5nLmNvbQ',
  nylasConfig: {
    nylasUrl: 'https://api.us.nylas.com/v3/grants',
    nylasApiKey:
      'nyk_v0_4Th3CuFKE33Cf3o4MYlqyAO22HIwDelvWzn0Nh05oEhDxPPgWfGD9BQZEDNTsFkK',
    nylasGrantId: 'f6b1094a-c233-4729-b00b-b0a4e27c1684',
  },
  streamChat: {
    apiKey: 'y63ftqnrpxkq',
  }
};
